import React, { useState, useEffect, memo, useMemo } from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import Layout from '../components/layout'
import ProductPreview from '../components/product-preview'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
const Products = (props) => {
  const defaultCategory = 'All Products'
  const navigationCategory = props?.location?.state?.category

  const [activeCategory, setActiveCategory] = useState(
    navigationCategory || defaultCategory
  )

  useEffect(() => {
    setActiveCategory(navigationCategory || defaultCategory)
  }, [navigationCategory])

  const products = props.data.allContentfulProduct.nodes

  const categories = [
    'All Products',
    'Geosynthetics',
    'Erosion Control',
    'Agro Textiles',
    'Industrial Textiles',
    'Packaging Textiles',
    'Accessories',
  ]

  const currentProducts = useMemo(() => {
    const sortedProducts = products.sort(
      (a, b) => categories.indexOf(a.category) - categories.indexOf(b.category)
    )

    const filteredProducts = sortedProducts.filter(
      (product) => product.category === activeCategory
    )

    return activeCategory === defaultCategory
      ? sortedProducts
      : filteredProducts
  }, [activeCategory, products])

  return (
    <Layout location={props.location}>
      <Seo title="Products" />
      <Container>
        <div className="mb-3 mt-5">
          <h5 className="text-gold">PRODUCTS</h5>

          {categories &&
            categories.map((category) => {
              if (!category) return <></>

              return (
                <Button
                  size="sm"
                  className={
                    activeCategory === category
                      ? 'button-blue mb-2 me-2'
                      : 'button-gold mb-2 me-2'
                  }
                  key={category}
                  onClick={() => setActiveCategory(category)}
                >
                  {category}
                </Button>
              )
            })}
        </div>

        <ProductPreview products={currentProducts} />
      </Container>
      <Container className="section section-gray" fluid>
        <Container>
          <h1>New Products 2022*</h1>
          <ul className="fancy-list">
            <li>Sodstaples/Round Top Pins</li>
            <li>Coirs</li>
          </ul>
          <h1>Launching in 2023*</h1>
          <Row>
            <Col xs={12} sm={4}>
              <strong>Geosynthetics</strong>
              <ul className="fancy-list">
                <li>Safety Fence</li>
                <li>Wire Backed Silt Fence</li>
              </ul>
            </Col>
            <Col xs={12} sm={4}>
              <strong>Erosion Control</strong>
              <ul className="fancy-list">
                <li>Dewatering Bags</li>
                <li>Gabions</li>
              </ul>
            </Col>
            <Col xs={12} sm={4}>
              <strong>Agrotextiles</strong>
              <ul className="fancy-list">
                <li>Agriculture Barn Curtain</li>
                <li>Agri-Liners</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={4}>
              <strong>Industrial Textiles</strong>
              <ul className="fancy-list">
                <li>All products</li>
              </ul>
            </Col>
            <Col xs={12} sm={4}>
              <strong>Packaging Textile</strong>
              <ul className="fancy-list">
                <li>Container Liners</li>
                <li>BOPP Bags</li>
              </ul>
            </Col>
            <Col xs={12} sm={4}>
              <strong>Accessories</strong>
              <ul className="fancy-list">
                <li>Tpost</li>
                <li>Woodstakes</li>
                <li>Welded hardware cloth</li>
                <li>Fences</li>
                <li>Wire Rod</li>
                <li>Hog Rings</li>
                <li>Spikes</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}

export default memo(Products)

export const pageQuery = graphql`
  query ProductsQuery {
    allContentfulProduct(sort: { fields: category }) {
      nodes {
        slug
        name
        category
        image {
          file {
            url
          }
          gatsbyImageData
        }
        description {
          raw
        }
        attachments {
          publicUrl
          title
        }
      }
    }
  }
`
