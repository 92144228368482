import React from 'react'

import * as styles from './tags.module.css'

const Category = ({ category }) =>
  category?.length > 0 && (
    <small className={styles.tags}>
      <div className={styles.tag}>{category}</div>
    </small>
  )

export default Category
