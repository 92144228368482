import React, { memo } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Category from './category'
import * as styles from './article-preview.module.css'

const ProductPreview = ({ products }) => {
  if (!products) return null
  if (!Array.isArray(products)) return null

  return (
    <div>
      {products.map((product) => {
        return (
          <Row key={product.slug} id={product.slug} className="pb-5 pt-5">
            <Col md={8} sm={6} className="mb-2">
              <h2>{product.name}</h2>
              <div>
                {product?.description?.raw &&
                  renderRichText(product.description)}
              </div>
              {product?.attachments && (
                <div>
                  <span className="muted-text">Specifications:</span>
                  {product?.attachments.map((attachment, i) => (
                    <a
                      href={attachment?.publicUrl}
                      className="attachment"
                      target="_blank"
                      rel="noreferrer"
                      key={i}
                    >
                      {attachment?.title}
                    </a>
                  ))}
                  <hr />
                </div>
              )}
              <div className={styles.muted}>
                Technical Specifications available upon enquiry
              </div>
              <div className={styles.meta}>
                <Category category={product.category} />
              </div>
            </Col>
            <Col md={4} sm={6}>
              {product?.image && (
                <GatsbyImage
                  alt={product.name}
                  image={product?.image[0].gatsbyImageData}
                  width={300}
                />
              )}
            </Col>
          </Row>
        )
      })}
    </div>
  )
}

export default memo(ProductPreview)
